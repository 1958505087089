import axios from 'axios';
import { RootState } from '../store';
import { Cookies } from 'react-cookie';
import { Action, createAsyncThunk } from '@reduxjs/toolkit';
import apiVRR, { ApiVRR, returnError } from '../../libs/axios/apis';
import { saveSession } from '../../libs/session';
import endPoints from '../../services/endpoints';
import { NOTIFICATIONS_TYPES } from '../../services/constants/notificationTypes';
import { TAccountDates, TFeatures, TNotification } from '../types';
import { IAccountState } from '../../micro_front_end/shared_components/vrr_dropdown/types';
import { INotify } from '../../libs/notifications/types';
import END_POINTS from '../../services/endpoints';

const HOST = process.env.REACT_APP_SERVER_URL;

export const isRejectedAction = (action: Action) => {
  return action.type.endsWith('rejected');
};

export const isPendingAction = (action: Action) => {
  return action.type.endsWith('pending');
};

export const fetchNotifications = createAsyncThunk(
  'notification/fetchNotifications',
  async () => {
    const response = await apiVRR().get(endPoints.USER.NOTIFICATIONS);
    return response.data;
  }
);

export const updateNotification = createAsyncThunk(
  'notification/updateNotification',
  async (param: number) => {
    const response = await apiVRR().patch(endPoints.USER.NOTIFICATIONS, {
      ids: [param]
    });
    return response.data;
  }
);

export const fetchAccounts = createAsyncThunk<
  Promise<any>,
  string,
  { state: RootState }
>('account/fetchAccounts', async (p: string, { getState }) => {
  const userId = getState().app.vrr.id;

  if (userId) {
    return apiVRR().get(endPoints.USER.GET_ACCOUNTS(userId), {
      params: {
        provider: p
      }
    });
  }
});

export const fetchAccountDates = createAsyncThunk<
  Promise<any>,
  string,
  { state: RootState }
>('account/fetchAccountDates', async (accountID: string, { getState }) => {
  const response = await apiVRR().get(
    endPoints.ACCOUNTS_ENDPOINTS.ACCOUNT_DATES(accountID),
    {
      params: {
        account_id: getState().userPreferences.settings.vrr.current_account_id
      }
    }
  );
  return response.data.data;
});

export const signin = createAsyncThunk(
  'login/signin',
  async ({ email, password }: { email: string; password: string }) => {
    try {
      const response = await axios.post(`${HOST}${endPoints.USER.SIGNIN}`, {
        email,
        password
      });
      return await saveSession(response);
    } catch (err) {
      return returnError(
        err as {
          response?: { data?: any; message: any; status: any; config?: any };
        }
      );
    }
  }
);

export const forgotPassword = createAsyncThunk(
  'login/forgotPassword',
  async ({ email }: { email: string }) => {
    try {
      return await axios.post(`${HOST}${endPoints.USER.FORGOT_PASSWORD}`, {
        email
      });
    } catch (err) {
      return returnError(
        err as {
          response?: { data?: any; message: any; status: any; config?: any };
        }
      );
    }
  }
);

export const fetchUserInformation = createAsyncThunk(
  'users/fetchUserInformation',
  async () => {
    const endpointURL = `/users/tokens/info`;
    const response = await new ApiVRR('').createHttpClient().get(endpointURL);

    return response.data;
  }
);

export const fetchUserFeaturesOnly = createAsyncThunk(
  'users/fetchUserFeaturesOnly',
  async () => {
    const endpointURL = `/users/tokens/info`;
    const response = await new ApiVRR('').createHttpClient().get(endpointURL);

    return response.data;
  }
);

export const sendReport = createAsyncThunk(
  'app/sendReport',
  async (params: { userId: number; videoId: number; message: string }) => {
    const { userId, videoId, message } = params;
    const endPoint = END_POINTS.USER.REPORT;

    const response = await apiVRR().post(endPoint, {
      user_id: userId,
      video_id: videoId,
      event_type: 'src_error',
      message,
      report_as_history_log: false,
      report_to_bugsnag: true
    });

    return response.data.data;
  }
);

export const logoutAction = async (): Promise<any> => {
  try {
    new Cookies().remove('access_token', { path: '/' });
    localStorage.removeItem('vrr-adtech');
    window.location.reload();
  } catch (err) {
    return Promise.reject(err);
  }
};

export const manageError = (
  state: Record<any, any>,
  action: Action,
  callback?: () => void
): any => {
  let type = '';
  if (!!(action as any)?.error?.message) {
    const error = JSON.parse((action as any).error.message);

    switch (error.status) {
      case 400:
        if (error?.data === 'Authorization header is malformed.' && callback) {
          callback();
        } else {
          type = NOTIFICATIONS_TYPES.FORM_ERROR;
        }
        break;
      case 401:
        if (error?.url === `${HOST}${endPoints.USER.SIGNIN}`) {
          type = NOTIFICATIONS_TYPES.WRONG_PASSWORD;
        } else if (callback) {
          callback();
        }
        break;
      case 409:
        type = NOTIFICATIONS_TYPES.CONFLICT;
        break;
      default:
        type = NOTIFICATIONS_TYPES.SERVER_ERROR;
        break;
    }
  }
  return {
    ...state,
    vrr: {
      ...state.vrr
    },
    notification: {
      type
    }
  };
};

export const getAccounts = (state: RootState): IAccountState[] =>
  state.app.accounts;

export const getNotification = (state: RootState): INotify =>
  state.app.notification;

export const getCurrentSession = (state: RootState): string | undefined =>
  state.app.vrr.session;

const getCurrentAccountInfos = (state: RootState) => {
  const { current_account_id } = state.userPreferences.settings.vrr;
  return state.app.accounts.filter(
    (acc: IAccountState) => acc.id === current_account_id
  )[0];
};

export const getCurrencyCode = (state: RootState): string | undefined => {
  const account = getCurrentAccountInfos(state);
  return account?.currency_code;
};

export const getForgotPasswordState = (state: RootState): string =>
  state.app.forgotPassword;

export const getAccountDates = (state: RootState): TAccountDates => {
  return state.app.accountDates;
};

export const getCurrentLabel = (state: RootState): string => state.app.funnel;

export const getNotifications = (
  state: RootState
): TNotification[] | undefined => state.app.notifications;

export const getUserId = (state: RootState): number | undefined =>
  state.app.vrr.id;

export const getIsLoading = (state: RootState): boolean => state.app.isLoading;

export const getFeatures = (state: RootState): TFeatures | undefined =>
  state.app.features;
