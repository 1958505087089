import React from 'react';
import { TLoaderGallery } from './types';
import './LoaderGallery.scss';

const LoaderGallery: React.FC<TLoaderGallery> = ({
  isEnable,
  children,
  itemNumber = 8
}) => {
  return (
    <>
      <div className={`${!isEnable ? 'section-hide' : ''}`}>
        <div className="loader-gallery">
          {Array.from({ length: itemNumber }, (_, i) => (
            <div className="loader-gallery-card" key={i} />
          ))}
        </div>
      </div>
      <div className={`${isEnable ? 'section-hide' : ''}`}>{children}</div>
    </>
  );
};

export default LoaderGallery;
