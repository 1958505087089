import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CreativeList from './creative_list';
import CreativeFilter from './creative_filter';
import CreativeTagModal from './creative_tag_modal';
import LoaderGallery from '../../loader/loader_gallery';
import { VrrBreadCrumb } from '../../shared_components';
import {
  getFolderVideoIds,
  prepareBreadcrumb,
  updateSelections
} from './services';
import search from '../../services/search';
import sort from '../../services/sort';
import URLS from '../../routing/urls';
import { SORT_BY } from '../../shared_components/vrr_sort_by/constants';
import {
  addFolder,
  archive,
  fetchFolder,
  fetchRoot,
  fetchVideoById,
  moveVideo
} from '../../../redux/creativeAnalytics/creativeGallery/actions/creativeGalleryAPIs';
import {
  getBreadcrumb,
  getFolders,
  getRootId,
  getTagVideos,
  getVideos
} from '../../../redux/creativeAnalytics/creativeGallery/actions/creativeGalleryActions';
import { getAccountId } from '../../../redux/userPreferences/actions/userPreferencesActions';
import { getLaunch, newLaunch } from '../../../redux/launcher/launcherActions';
import {
  TCreativeGalleryFolder,
  TCreativeGalleryVideo
} from '../../../redux/creativeAnalytics/creativeGallery/types';
import { TCreativeVideoFolder } from './creative_modal/types';

const CreativeVideoGallery: React.FC = () => {
  const dispatch: any = useDispatch();
  const history = useNavigate();

  const rootId = useSelector(getRootId);
  const videos = useSelector(getVideos);
  const folders = useSelector(getFolders);
  const account = useSelector(getAccountId);
  const breadcrumb = useSelector(getBreadcrumb);
  const tagVideos = useSelector(getTagVideos);
  const launch = useSelector(getLaunch);

  const [folderList, setFolderList] = useState<
    TCreativeGalleryFolder[] | undefined
  >(undefined);
  const [videoList, setVideoList] = useState<
    TCreativeGalleryVideo[] | undefined
  >(undefined);
  const [videoSelections, setVideoSelections] = useState<number[]>([]);
  const [folderSelections, setFolderSelections] = useState<number[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [sortLabel, setSortLabel] = useState('name');
  const [sortOrder, setSortOder] = useState(SORT_BY.asc);
  const [isGallery, setIsGallery] = useState(true);

  useEffect(() => {
    if (!isGallery && launch && account?.length) {
      history({
        pathname: URLS.LAUNCHER.replace(
          ':accountId/:launcherId',
          `${account}/${launch.id}`
        )
      });
    }
  }, [isGallery, launch]);

  const initializeList = () => {
    setIsGallery(true);
    setVideoList(undefined);
    setFolderList(undefined);
    setVideoSelections([]);
    setFolderSelections([]);
  };

  const goToRoot = () => {
    if (account?.length) {
      initializeList();
      dispatch(fetchRoot(account));
    }
  };

  useEffect(() => {
    goToRoot();
  }, [account]);

  const onSort = (label: string, order: string) => {
    setSortLabel(label);
    setSortOder(order);
    setVideoList((prev) => sort(prev ?? [], order, label));
    setFolderList((prev) => sort(prev ?? [], order, label));
  };

  useEffect(() => {
    if (folders) {
      setFolderList(folders);
      onSort(sortLabel, sortOrder);
    }

    if (videos) {
      setVideoList(videos);
      onSort(sortLabel, sortOrder);
    }
  }, [folders, videos]);

  const onDragOver = (e: React.DragEvent<HTMLDivElement>) => e.preventDefault();

  const onSelectVideo = (videoId: number, isCheck: boolean) => {
    const newVideoSelections = updateSelections(
      videoId,
      isCheck,
      videoSelections
    );
    setVideoSelections(newVideoSelections);
    return newVideoSelections;
  };

  const onMove = (videoId: number, folderId: number) => {
    const newVideoSelections = onSelectVideo(videoId, true);
    if (folderId) {
      initializeList();
      dispatch(
        moveVideo({ folderDestination: folderId, videos: newVideoSelections })
      );
      dispatch(fetchFolder(folderId.toString()));
    }
  };

  const onMoveToRoot = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const videoId = +e.dataTransfer.getData('text');
    const newVideoSelections = onSelectVideo(videoId, true);
    if (rootId) {
      dispatch(
        moveVideo({
          folderDestination: rootId,
          videos: newVideoSelections
        })
      );
      goToRoot();
    }
  };

  const onSelectFolder = (folderId: number, isCheck: boolean) => {
    const newFolderSelections = updateSelections(
      folderId,
      isCheck,
      folderSelections
    );
    setFolderSelections(newFolderSelections);
  };

  const onNewFolder = (newFolder: TCreativeVideoFolder) => {
    if (newFolder) {
      dispatch(
        addFolder({
          name: newFolder.name,
          videos: videoSelections
        })
      );
    }
  };

  const onSearch = (searchValue?: string) => {
    setVideoList(search(videos ?? [], 'name', searchValue));
    setFolderList(search(folders ?? [], 'name', searchValue));
    onSort(sortLabel, sortOrder);
  };

  const onArchive = () => {
    if (rootId) {
      dispatch(
        archive({
          data: {
            folderId: breadcrumb?.length ? breadcrumb[1]?.id : rootId,
            videosIds: videoSelections,
            foldersIds: folderSelections
          }
        })
      );
      initializeList();
    }
  };

  const onAddTag = () => {
    const videoIds: number[] = getFolderVideoIds(
      videoSelections,
      folderSelections,
      folderList
    );
    if (videoIds.length) {
      dispatch(fetchVideoById(videoIds));
      setIsOpen(true);
    }
  };

  const onLaunch = () => {
    const videoIds: number[] = getFolderVideoIds(
      videoSelections,
      folderSelections,
      folderList
    );
    if (videoIds.length) {
      setIsGallery(false);
      dispatch(newLaunch(videoIds));
    }
  };

  const onCloseModal = () => {
    setIsOpen(false);
    goToRoot();
  };

  return (
    <div className="creative-video-gallery">
      <div onDrop={onMoveToRoot} onDragOver={onDragOver} role="listitem">
        <VrrBreadCrumb
          items={prepareBreadcrumb(breadcrumb)}
          onClickItem={goToRoot}
        />
      </div>
      <CreativeFilter
        videoSelections={videoSelections}
        folderSelections={folderSelections}
        folderList={folderList}
        onNewFolderAction={onNewFolder}
        onSortAction={onSort}
        onSearchAction={onSearch}
        onArchiveAction={onArchive}
        onAddTagAction={onAddTag}
        onLaunchAction={onLaunch}
      />
      <LoaderGallery isEnable={!videoList || !folderList}>
        <CreativeList
          videos={videoList ?? []}
          folders={folderList ?? []}
          onDropAction={onMove}
          onSelectVideoAction={onSelectVideo}
          onSelectFolderAction={onSelectFolder}
          onGoFolderAction={initializeList}
        />
      </LoaderGallery>
      {isOpen && (
        <CreativeTagModal
          isOpen={isOpen}
          videos={tagVideos ?? []}
          onCloseAction={onCloseModal}
        />
      )}
    </div>
  );
};

export default CreativeVideoGallery;
