import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import i18n from 'i18next';
import NOTIFICATIONS_TYPES from '../../services/constants/notificationTypes';
import {
  signin,
  manageError,
  isRejectedAction,
  isPendingAction,
  forgotPassword,
  fetchAccounts,
  fetchAccountDates,
  logoutAction,
  fetchNotifications,
  updateNotification,
  fetchUserInformation,
  fetchUserFeaturesOnly
} from './appActions';
import { currentUserDataSession } from '../../libs/session';
import { TVrrState } from '../types';
import { IAccountAPI } from '../../micro_front_end/shared_components/vrr_dropdown/types';

export const initialState: TVrrState = {
  isLoading: false,
  features: undefined,
  vrr: {
    id: undefined,
    session: currentUserDataSession(),
    error: { msg: null },
    role: undefined
  },
  accounts: [],
  notification: {},
  notifications: undefined,
  forgotPassword: '',
  error: {},
  accountDates: {
    lastUpdateDate: undefined,
    lastOptimizationDate: undefined,
    nextOptimizationDate: undefined
  },
  funnel: 'all'
};

export const appSlice = createSlice({
  name: 'app',
  initialState: { ...initialState },
  reducers: {
    notify: (state, action: PayloadAction<any>) => {
      return {
        ...{
          ...state,
          notification: action.payload
        }
      };
    },
    updateAccount: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        accounts: action.payload
      };
    },
    updateLabel: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        funnel: action.payload
      };
    },
    logout: () => {
      logoutAction().then(() => {
        return {
          ...initialState
        };
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchNotifications.fulfilled, (state, { payload }) => {
      return {
        ...state,
        notifications: payload
      };
    });
    builder.addCase(updateNotification.fulfilled, (state, { payload }) => {
      return {
        ...state,
        notifications: payload
      };
    });
    builder.addCase(fetchAccounts.fulfilled, (state, { payload }: any) => {
      return {
        ...state,
        accounts: payload.data.data.list.map((item: IAccountAPI) => {
          const { account_name, ...rest } = item;
          return { ...rest, label: account_name, value: item.id };
        })
      };
    });
    builder.addCase(fetchAccountDates.fulfilled, (state, { payload }: any) => {
      return {
        ...state,
        accountDates: payload
      };
    });
    builder.addCase(signin.fulfilled, (state, action: PayloadAction<any>) => {
      return {
        ...state,
        isLoading: false,
        features: action.payload.features_map ?? {},
        vrr: {
          ...state.vrr,
          id: action.payload.id,
          session: action.payload.email,
          role: !!action.payload.role ? action.payload.role : ''
        }
      };
    });
    builder.addCase(signin.pending, (state) => {
      return {
        ...state,
        isLoading: true
      };
    });
    builder.addCase(fetchUserInformation.pending, (state) => {
      return {
        ...state,
        isLoading: true,
        features: undefined,
        vrr: {
          ...initialState.vrr
        }
      };
    });
    builder.addCase(fetchUserInformation.rejected, (state) => {
      return {
        ...state,
        isLoading: false,
        features: undefined,
        vrr: {
          ...initialState.vrr,
          role: ''
        }
      };
    });
    builder.addCase(
      fetchUserInformation.fulfilled,
      (state, action: PayloadAction<any>) => {
        return {
          ...state,
          isLoading: false,
          features: action.payload.features_map,
          vrr: {
            ...state.vrr,
            id: action.payload.id,
            session: action.payload.email,
            role: !!action.payload.role ? action.payload.role : ''
          }
        };
      }
    );
    builder.addCase(
      fetchUserFeaturesOnly.fulfilled,
      (state, action: PayloadAction<any>) => {
        return {
          ...state,
          features: action.payload.features_map
        };
      }
    );
    builder.addCase(forgotPassword.fulfilled, (state) => {
      return {
        ...state,
        notification: {
          type: 'customType',
          options: {
            type: 'success',
            icon: 'tim-icons icon-check-2',
            autoDismiss: 3,
            message: i18n.t('components.forgot_password.notifications.success'),
            place: 'tc'
          }
        },
        forgotPassword: NOTIFICATIONS_TYPES.SUCCESS
      };
    });
    builder.addCase(forgotPassword.rejected, (state, action) => {
      const status = action?.error?.message
        ? JSON.parse(action.error.message).status
        : null;
      const notificationMessage =
        status === 401
          ? 'components.forgot_password.notifications.bad_email'
          : 'components.notification.serverError.message';
      return {
        ...state,
        notification: {
          type: 'customType',
          options: {
            type: 'danger',
            autoDismiss: 3,
            message: i18n.t(notificationMessage),
            place: 'tc'
          }
        }
      };
    });
    builder.addMatcher(isRejectedAction, (state, action) => {
      if (
        !(
          action.type === 'login/forgotPassword/rejected' ||
          action.type === 'accounts/budgets/rejected' ||
          action.type === 'creativeGallery/importAction/rejected'
        )
      ) {
        return manageError(state, action, appSlice.caseReducers.logout);
      }
    });
    builder.addMatcher(isPendingAction, (state) => {
      return {
        ...state,
        notification: {}
      };
    });
  }
});

export const { notify, updateAccount, updateLabel, logout } = appSlice.actions;

export default appSlice.reducer;
