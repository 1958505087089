import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import SectionTitle from '../section_title';
import VrrVideoPlayer from '../../../shared_components/vrr_video_player';
import { prepareSource } from './services';
import { getFeatures, getUserId } from '../../../../redux/app/appActions';
import { updateVideoDetails } from '../../../../redux/creativeAnalytics/creativeVideoDetails/creativeVideoDetailsAction';
import { TPlayerSection } from './types';
import { getFeatureByPath } from '../../../routing/services';

const PlayerSection: React.FC<TPlayerSection> = ({
  videoId,
  accountId,
  token,
  chapters,
  saliencyData
}) => {
  const { t } = useTranslation();
  const params: any = useParams();
  const dispatch: any = useDispatch();
  const userId = useSelector(getUserId);
  const features = useSelector(getFeatures);
  const [source, setSource] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (
      !!accountId?.length &&
      !!videoId?.length &&
      !!token?.length &&
      !!userId
    ) {
      const src = prepareSource(accountId, videoId, token, userId.toString());
      setSource(src);
    }
  }, [accountId, videoId, userId, token]);

  useEffect(() => {
    return () => {
      setSource(undefined);
    };
  }, []);

  const onUpdate = (isCheck: boolean, starId?: string) => {
    if (getFeatureByPath(features, 'creative.edit_details')) {
      dispatch(
        updateVideoDetails({
          videoId: params.videoId,
          isActive: isCheck,
          objectId: starId
        })
      );
    }
  };

  return (
    <>
      <SectionTitle
        title={t('creative_video_details.section_title.player_section')}
        iconClassName="tim-icons icon-atom"
      />
      {!!source?.length && (
        <VrrVideoPlayer
          id={`player-section-${uuid()}`}
          videoId={videoId}
          width="960"
          height="540"
          source={[
            {
              type: 'video/webm',
              src: source
            }
          ]}
          chapters={chapters}
          saliencyData={saliencyData}
          onStarClickAction={onUpdate}
        />
      )}
    </>
  );
};

export default PlayerSection;
