import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  registerables,
  ChartData
} from 'chart.js';
import ChartOptions from './chart_options';
import LoaderGallery from '../../loader/loader_gallery';
import { VrrDropdown } from '../../shared_components';
import { getCurrentCurrency } from '../../../services/services';
import {
  getCurrentProvider,
  providers
} from '../../../services/provider/getCurrentProvider';
import {
  checkAccount,
  checkFunnel,
  checkPeriod,
  checkRange,
  getDefaultValue,
  getDropdownValues,
  setDataOptions,
  setDataService
} from './services';
import {
  fetchCharts,
  getCharts
} from '../../../redux/overview/overviewActions';
import {
  getAccountId,
  getGMSettingsByCurrentAccount,
  getPeriod
} from '../../../redux/userPreferences/actions/userPreferencesActions';
import { getCurrentLabel } from '../../../redux/app/appActions';
import { TOptions } from './chart_options/types';
import { TRangePeriod } from './types';
import { TDateRange } from '../../nav_bar/date_time_picker/types';
import { TCurrencyOptions } from '../../services/formatters/types';
import './Chart.scss';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  ...registerables
);

const Chart: React.FC = () => {
  const { t } = useTranslation();
  const dispatch: any = useDispatch();
  const allCharts = useSelector(getCharts);
  const currentAccountId = useSelector(getAccountId);
  const period = useSelector(getPeriod);
  const currentGlobalMetrics = useSelector(getGMSettingsByCurrentAccount);
  const funnel = useSelector(getCurrentLabel);
  const localOptions: TCurrencyOptions = {
    thousandsSeparator: t('local.separators.thousands'),
    decimalSeparator: t('local.separators.decimal'),
    symbol: getCurrentCurrency()
  };

  const [charts, setCharts] = useState(allCharts);
  const [currentPeriod, setCurrentPeriod] = useState<TDateRange | null>(null);
  const [currentFunnel, setCurrentFunnel] = useState(funnel);
  const [accountId, setAccountId] = useState<string | null>('');
  const [data, setData] = useState<ChartData<'line'>>({
    labels: [],
    datasets: []
  });
  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState<any>({
    animations: false,
    responsive: true,
    aspectRatio: 6,
    plugins: {
      legend: {
        display: false
      }
    }
  });
  const [chartRef, setChatRef] = useState(null);
  const [currentTypeData, setCurrentTypeData] =
    useState<TOptions['id']>('value');
  const [rangePeriod, setRangePeriod] = useState<TRangePeriod>({
    label: '',
    value: ''
  });
  const [previousRange, setPreviousRange] = useState<TRangePeriod>({
    label: '',
    value: ''
  });
  const [dropdownValues, setDropdownValues] = useState<TRangePeriod[]>([]);

  const [provider, setProvider] = useState<string>('all');

  useEffect(() => {
    const currentProvider = getCurrentProvider();
    if (providers.includes(currentProvider)) {
      setProvider(currentProvider);
    }
  });

  const ref = useCallback((node: any) => {
    setChatRef(node);
  }, []);

  useEffect(() => {
    if (currentPeriod?.from && currentPeriod?.to) {
      setRangePeriod(getDefaultValue(currentPeriod));
      setDropdownValues(getDropdownValues(currentPeriod));
    }
  }, [currentPeriod]);

  const checkAccountDiff = () => {
    return (
      currentPeriod?.from &&
      currentPeriod?.to &&
      currentAccountId &&
      (checkPeriod(period, currentPeriod) ||
        checkAccount(currentAccountId, accountId) ||
        checkFunnel(funnel, currentFunnel) ||
        checkRange(previousRange.value, rangePeriod.value))
    );
  };

  useEffect(() => {
    if (period && currentAccountId && !!provider.length && checkAccountDiff()) {
      setLoading(true);
      dispatch(
        fetchCharts({
          accountId: currentAccountId,
          period: period,
          rangePeriod: rangePeriod.value,
          p: provider
        })
      );
    }
  }, [currentAccountId, provider, rangePeriod, period]);

  useEffect(() => {
    if (period?.to && period?.from) {
      setPreviousRange(rangePeriod);
      setCurrentPeriod(period);
      setAccountId(currentAccountId);
      setCurrentFunnel(funnel);
    }
  }, [period, funnel, currentAccountId]);

  useEffect(() => {
    if (dropdownValues.length && Object.values(allCharts).length > 0) {
      setCharts(allCharts);
      setLoading(false);
    }
  }, [allCharts, dropdownValues]);

  useEffect(() => {
    if (chartRef && charts && currentTypeData) {
      const chartData = setDataService(
        charts,
        chartRef,
        currentTypeData,
        currentGlobalMetrics
      );
      const chartOptions = setDataOptions(
        charts,
        currentTypeData,
        localOptions
      );

      setData(chartData);
      setOptions(chartOptions);
    }
  }, [chartRef, charts, currentTypeData, currentGlobalMetrics]);

  const handleChangeData = (dataId: TOptions['id']) => {
    setCurrentTypeData(dataId);
  };

  return (
    <div className="chart-container">
      <LoaderGallery isEnable={loading} itemNumber={1}>
        <Card className="card-chart">
          <div className="chart-header">
            <h5>{t('components.chart.title')}</h5>
            <ChartOptions onClickAction={handleChangeData} />
            <div className="select-range-period">
              <VrrDropdown
                list={dropdownValues}
                handleChangeAction={(e: any) => {
                  setPreviousRange(rangePeriod);
                  setRangePeriod(
                    e ||
                      ({
                        value: 'weekly',
                        label: t('components.charts.weeks')
                      } as TRangePeriod)
                  );
                }}
                currentValue={rangePeriod.value}
              />
            </div>
          </div>
          <Line ref={ref} data={data} options={options} />
        </Card>
      </LoaderGallery>
    </div>
  );
};

export default Chart;
