import React, { useEffect, useState, useCallback } from 'react';
import { CardColumns } from 'reactstrap';
import MetricCard from '../metric_card';
import MetricDetail from '../../metric_detail';
import MetricModal from '../../metric_modal';
import LoaderGallery from '../../../../loader/loader_gallery';
import { VrrAddCard } from '../../../../shared_components';
import { metricDesignConst } from '../metric_card/constants';
import { DEFAULT_MAIN_KPI } from '../../metric_detail/metric_detail_header/kpi_dropdown/services';
import { TMetricCardContent, TMetricToDetail } from '../metric_card/types';
import { TMetricsGallery } from './types';
import { TMetricKey, TSetOfMetrics } from 'redux/overview/metrics/types';
import { TMetricTable } from '../metric_table/types';
import './MetricsGallery.scss';

const MetricsGallery: React.FC<TMetricsGallery> = ({
  currentMetrics,
  metricsList,
  mainKpi
}: TMetricsGallery) => {
  const [data, setData] = useState<TSetOfMetrics>();
  const [open, setOpen] = useState(false);
  const [childCount, setChildCount] = useState(0);
  const [openMetrics, setOpenMetrics] = useState(false);
  const [currentSegment, setCurrentSegment] = useState<TMetricToDetail>();

  useEffect(() => {
    return () => {
      setChildCount(0);
    };
  }, []);

  useEffect(() => {
    if (!!metricsList) {
      setData(metricsList);
    }
  }, [metricsList]);

  const openMetricDetails = (metricToDetail: TMetricToDetail) => {
    setCurrentSegment(metricToDetail);
    setOpen(true);
  };

  const closeModal = () => {
    setCurrentSegment(undefined);
    setOpen(false);
  };

  const makeMetricCardProps = (metric: TMetricKey): TMetricCardContent => {
    const metricData: TMetricTable['data'] = !!data
      ? ({ [metric]: data[metric]?.data } as TMetricTable['data'])
      : {};

    return {
      metricName: metric,
      MetricDesign: metricDesignConst[metric],
      kpiSelected: ['name', mainKpi ?? DEFAULT_MAIN_KPI],
      data: metricData
    };
  };

  const checkAllMetrics = useCallback(() => {
    setChildCount((prev) => prev + 1);
  }, []);

  return (
    <div className="metrics-gallery">
      <LoaderGallery
        isEnable={childCount < currentMetrics.length}
        itemNumber={1}
      >
        <CardColumns className="card-columns" data-cy-id="overview-details">
          {data &&
            currentMetrics?.map((currentMetric: TMetricKey) => {
              return (
                <MetricCard
                  key={currentMetric}
                  metric={makeMetricCardProps(currentMetric)}
                  onClickAction={openMetricDetails}
                  onReady={checkAllMetrics}
                />
              );
            })}
          <div className="metric-card" data-cy-id="overview-add-details-button">
            <VrrAddCard onClickAction={() => setOpenMetrics(true)} />
          </div>
        </CardColumns>
        {currentSegment && open && (
          <MetricDetail
            type="modal"
            metric={currentSegment.metricName}
            isOpen={open}
            onCloseAction={closeModal}
          />
        )}
        <MetricModal
          isOpen={openMetrics}
          onCancelAction={() => setOpenMetrics(false)}
        />
      </LoaderGallery>
    </div>
  );
};

export default MetricsGallery;
