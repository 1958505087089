import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import { Trans, useTranslation } from 'react-i18next';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Form,
  Input,
  InputGroupText,
  InputGroup,
  FormGroup
} from 'reactstrap';
import { VrrLink } from '../../micro_front_end/shared_components';
import URLS from '../../micro_front_end/routing/urls';
import signInBackground from '../../assets/vrr/images/Background_signin.svg';
import logoVRR from '../../assets/vrr/images/logo_vrr.svg';
import { getCurrentSession, signin } from '../../redux/app/appActions';
import './SignIn.scss';

const SignIn: React.FC = () => {
  const { t } = useTranslation();
  const dispatch: any = useDispatch();
  const history = useNavigate();
  const session = useSelector(getCurrentSession);
  const [email, setEmail] = useState('');
  const [pwd, setPwd] = useState('');

  useEffect(() => {
    if (session?.length) {
      history(URLS.HOME);
    }
  }, [session]);

  const login = () => {
    dispatch(signin({ email, password: pwd }));
  };

  const onKeyUp = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      login();
    }
  };

  return (
    <Form className="form" onKeyDown={(e: any) => onKeyUp(e)}>
      <Card className="card-login card-white">
        <CardBody className="vrr-login-card">
          <div className="background-compo">
            <img id="img-red" alt="..." src={signInBackground} />
            <img id="img-title" alt="..." src={logoVRR} />
          </div>
          <div className="inside-card-body">
            <InputGroup
              className={classnames({
                'input-group-focus': true
              })}
            >
              <InputGroupText>
                <i className="tim-icons icon-email-85" />
              </InputGroupText>
              <Input
                placeholder={t('components.signin.inputs.email.placeholder')}
                value={email}
                type="email"
                onChange={(ev: React.ChangeEvent<HTMLInputElement>) =>
                  setEmail(ev.target.value)
                }
              />
            </InputGroup>
            <InputGroup
              className={classnames({
                'input-group-focus': true
              })}
            >
              <InputGroupText>
                <i className="tim-icons icon-lock-circle" />
              </InputGroupText>
              <Input
                placeholder={t('components.signin.inputs.password.placeholder')}
                type="password"
                onChange={(ev: React.ChangeEvent<HTMLInputElement>) =>
                  setPwd(ev.target.value)
                }
              />
            </InputGroup>
            <FormGroup inline className="vrr-options">
              <a
                href={URLS.FORGOT_PASSWORD}
                className="vrr-options_forgot-password"
              >
                {t('components.signin.links.password_forgot')}
              </a>
            </FormGroup>
          </div>
        </CardBody>
        <CardFooter className="vrr-login-footer">
          <Button
            block
            data-cy-id="signin-submit"
            color="primary"
            className="btn-vrr-login mb-3 cy-btn-login"
            onClick={login}
            size="lg"
          >
            {t('components.signin.buttons.login')}
          </Button>
          <Button
            block
            color="secondary"
            className="btn-simple mb-3 btn-vrr-login btn-request-demo"
            href="https://www.videorunrun.com/get-started/"
            size="lg"
          >
            {t('components.signin.buttons.request_demo')}
          </Button>
          <p className="login-gdpr">
            <Trans
              t={t}
              i18nKey="login.gdpr.links"
              components={{
                link1: <VrrLink title="terms" link={URLS.TERMS} />,
                link2: <VrrLink title="policy" link={URLS.PRIVACY} />
              }}
            />
          </p>
        </CardFooter>
      </Card>
    </Form>
  );
};

export default SignIn;
