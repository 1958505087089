import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import initPlayer from '../../../libs/player/dist';
import { getUserId, sendReport } from '../../../redux/app/appActions';
import { TVrrVideoPlayer } from './types';
import '../../../libs/player/dist/style.css';

const VrrVideoPlayer: React.FC<TVrrVideoPlayer> = ({
  id,
  videoId,
  width,
  height,
  source,
  chapters,
  saliencyData,
  onStarClickAction
}) => {
  const dispatch: any = useDispatch();
  const userId = useSelector(getUserId);

  const onStarClick = (_: number, isCheck: boolean, starId?: string) => {
    if (onStarClickAction) onStarClickAction(isCheck, starId);
  };

  const onError = (_: string, error: any) => {
    if (userId && videoId) {
      dispatch(
        sendReport({
          userId,
          videoId: parseInt(videoId, 10),
          message: error.message
        })
      );
    }
  };

  useEffect(() => {
    initPlayer({
      id,
      errorCallback: onError,
      starCallback: onStarClick,
      sources: source,
      chapters: chapters ?? [],
      saliencyData: saliencyData ?? {}
    });
  }, []);

  return (
    <video
      key={id}
      id={id}
      data-cy-id="video-player"
      className="video-js"
      width={width}
      height={height}
    />
  );
};

export default VrrVideoPlayer;
