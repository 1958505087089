import React from 'react';
import LoaderGallery from '../../../loader/loader_gallery';
import { TLoaderGlobalMetric } from './types';
import './LoaderGlobalMetric.scss';

const LoaderGlobalMetric: React.FC<TLoaderGlobalMetric> = ({
  isEnable,
  children
}) => {
  return (
    <LoaderGallery isEnable={isEnable} itemNumber={5}>
      {children}
    </LoaderGallery>
  );
};

export default LoaderGlobalMetric;
