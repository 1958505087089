import React from 'react';
import { TMetricCard } from './types';
import MetricCardBody from './MetricCardBody';
import { VrrCard } from 'micro_front_end/shared_components';
import MetricCardStyles from './styles/MetricCard.module.scss';
import './styles/MetricCard.scss';

const MetricCard: React.FC<TMetricCard> = ({
  metric,
  onClickAction,
  onReady
}) => {
  return (
    <VrrCard
      data-cy-id="overview-details-card"
      className="metric-card"
      cardBody={{
        element: (
          <MetricCardBody
            metric={metric}
            onClickAction={onClickAction}
            onReady={onReady}
          />
        )
      }}
      cssModule={MetricCardStyles}
    />
  );
};

export default MetricCard;
