import React, { useEffect, useState } from 'react';
import { CardText, CardTitle } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { VrrButton, VrrCircleLoader } from '../../../../shared_components';
import MetricTable from '../metric_table';
import { DEFAULT_MAIN_KPI } from '../../metric_detail/metric_detail_header/kpi_dropdown/services';
import { TMetricCardBody } from './types';

const MetricCardBody: React.FC<TMetricCardBody> = ({
  metric,
  onClickAction,
  onReady
}) => {
  const { metricName, data, MetricDesign, kpiSelected } = metric;
  let clonedKpis: [string, string] = [...kpiSelected];
  const { metricIcon } = MetricDesign;
  const { t } = useTranslation();
  const name = t(`metrics.metrics.metric_card.name.${metricName}`);
  const isUndefined = Object.values(data)[0] === undefined;
  const isEmpty = Object.values(data)[0]?.length === 0;
  const [ready, setReady] = useState(false);

  useEffect(() => {
    return () => setReady(false);
  }, []);

  useEffect(() => {
    if (!ready && Object.values(metric.data)[0] !== undefined) {
      setReady(true);
      onReady();
    }
  }, [metric.data]);

  const handleOnClickAction = () => {
    if (onClickAction)
      onClickAction({
        metricName: metricName,
        data: data
      });
  };

  const dataToMetricCard = { ...data };

  if (name === 'Video') {
    dataToMetricCard.video = dataToMetricCard.video?.map((item) => {
      const result = { ...item };
      clonedKpis = ['thumbnail', clonedKpis[1] || DEFAULT_MAIN_KPI];
      return result;
    });
  }

  return (
    <>
      <CardTitle tag="h5">
        <span className="metric-card-title">
          <>{metricIcon}</>
          {name}
        </span>
        <VrrButton
          dataCy="overview-details-button"
          className="btn-round icon-btn"
          onClick={handleOnClickAction}
          icon={{
            iconLabel: 'tim-icons icon-notes'
          }}
          disabled={isUndefined || isEmpty}
        />
      </CardTitle>
      <div />
      <CardText tag="section">
        <VrrCircleLoader isEnable={isUndefined}>
          {isEmpty ? (
            <span data-cy="gi_error_message">
              {t('metrics.metrics.metric_card.metrics_error_message')}
            </span>
          ) : (
            <MetricTable
              id={`card-insight-${metricName
                .replace(/\s+/g, '-')
                .toLowerCase()}`}
              data={dataToMetricCard}
              metricName={metricName}
              limitColumnKeys={clonedKpis}
              limitRow={10}
            />
          )}
        </VrrCircleLoader>
      </CardText>
    </>
  );
};

export default MetricCardBody;
